import { useNavigate, useParams } from "react-router-dom";
import {
  getInstructor,
  getInstructorWage,
  updateInstructorWage,
} from "../../../../api/instructor";
import { Button, Col, Form, Row } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import { getUserMe } from "../../../../api/auth";
import InstructorWageOtherForm from "./component/OtherForm";
import InstructorWageIndividualForm from "./component/IndividualForm";
import InstructorWageGroupForm from "./component/GroupForm";
import CommaFormattedInput from "../../../../components/form/CommaFormattedInput";
import useUserMe from "../../../../hooks/useUserMe";

const { memo, useState, useEffect, Fragment } = require("react");

const InstructorWageView = memo(() => {
  const { instructorId } = useParams();
  const [instructorData, setInstructor] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    birth: "",
    position: "",
    pay: 0,
    hiddenPay: 0,
    store: [],
    createdAt: "",
    updatedAt: "",
  });
  const [wageData, setWageData] = useState({
    instructorId: "",
    storeId: "",
    wageDate: "",
    defaultPrice: 0,
    lessonPrice: 0,
    otherPrice: 0,
    sumPrice: 0,
    deductionPrice: 0,
    otherData: [],
  });
  const [formData, setFormData] = useState({
    instructorId: null,
    wageDate: "",
    individualPrice: 0,
    individualCount: 0,
    individualLessonCount: 0,
    individualLessonDiscount: 0,
    groupPrice: 0,
    groupCount: 0,
    groupLessonCount: 0,
    groupLessonDiscount: 0,
    otherData: [],
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/manage/instructor-wage/list"); // Navigate to the previous page in the history stack
  };
  const { userMeData } = useUserMe();

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(
    String(new Date().getMonth() + 1).padStart(2, "0")
  );

  const years = Array.from({ length: 3 }, (_, i) => currentYear - i);
  const months = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, "0"));

  useEffect(() => {
    const fetchInstructorInfo = async () => {
      setLoading(true);
      try {
        const instructor = await getInstructor(instructorId);
        const transInstructorData = {
          id: instructor.id,
          name: instructor.user.name,
          email: instructor.user.email,
          phone: instructor.user.phone,
          birth: instructor.user.birth,
          position: instructor.position,
          pay: instructor.pay,
          hiddenPay: instructor.hiddenPay,
          store: instructor.store,
          createdAt: instructor.createdAt,
          updatedAt: instructor.updatedAt,
        }
        setInstructor(transInstructorData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInstructorInfo();
  }, [instructorId]);

  useEffect(() => {
    const fetchInstructorInfo = async () => {
      try {
        const storeId = userMeData.store.id;
        const searchDate = `${currentYear}-${currentMonth}`;

        // 강사의 선택 월 급여 확인
        const res = await getInstructorWage(instructorId, storeId, searchDate);
        if (res.defaultPrice === 0) {
          res.defaultPrice = instructorData.pay + instructorData.hiddenPay;
        }
        res.storeId = storeId;
        setWageData(res);

        const resFormData = {
          instructorId: instructorId,
          wageDate: searchDate,
          individualPrice: res.lessonData.individualLesson.reduce((accumulator, current) => {
            return accumulator + current.price * current.logCount;
          }, 0),
          individualCount: res.lessonData.individualLesson.reduce((accumulator, current) => {
            return accumulator + current.logCount;
          }, 0),
          individualLessonCount: res.lessonData.individualLessonCount,
          individualLessonDiscount: 0,
          groupPrice: res.lessonData.groupLesson.reduce((accumulator, current) => {
            return accumulator + current.price;
          }, 0),
          groupCount: res.lessonData.groupLesson.reduce((accumulator, current) => {
            return accumulator + current.logCount;
          }, 0),
          groupLessonCount: res.lessonData.groupLessonCount,
          groupLessonDiscount: 0,
          otherData: res.otherData || [],
        };
        setFormData(resFormData);
      } catch (error) {
        setError(error.message);
      } finally {
        // finally
      }
    };

    fetchInstructorInfo();
  }, [instructorId, userMeData, instructorData, currentYear, currentMonth]);

  useEffect(() => {
    const individualLessonDiscountPrice = formData.individualPrice * (formData.individualLessonDiscount / 100);
    const groupLessonDiscountPrice = formData.groupPrice * (formData.groupLessonDiscount / 100);
    const lessonPrice =
      formData.groupPrice -
      groupLessonDiscountPrice +
      (formData.individualPrice - individualLessonDiscountPrice);

    var otherPrice = 0
    if (formData.otherData != null) {
      otherPrice = formData.otherData.reduce((accumulator, current) => {
        return Number(accumulator) + Number(current.price);
      }, 0);
    }
    
    // 3.3% 공제
    const sumPrice = (wageData.defaultPrice + lessonPrice + otherPrice)
    const deductionPrice = Math.floor(sumPrice * 0.967);

    setWageData((prevWageData) => ({
      ...prevWageData,
      lessonPrice,
      otherPrice,
      sumPrice,
      deductionPrice
    }));
  }, [formData, wageData.defaultPrice]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    const updatedWageData = {
      ...wageData,
      otherData: formData.otherData,
      wageDate: formData.wageDate
    };
    console.log(updatedWageData);

    try {
      await updateInstructorWage(updatedWageData);
      alert("저장되었습니다.");
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLessnDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleOtherDataChange = (index, field, value) => {
    const newOtherData = [...formData.otherData];
    newOtherData[index] = {
      ...newOtherData[index],
      [field]: value,
    };
    setFormData({ ...formData, otherData: newOtherData });
  };

  const addOtherData = () => {
    setFormData({
      ...formData,
      otherData: [...formData.otherData, { name: "", price: "" }],
    });
  };

  const removeOtherData = (index) => {
    // 기타급여 합계 계산
    const removeSumOtherPrice = wageData.otherPrice - formData.otherData[index].price;
    setWageData({ ...wageData, otherPrice: removeSumOtherPrice });

    const newOtherData = formData.otherData.filter((_, i) => i !== index);
    setFormData({ ...formData, otherData: newOtherData });
  };

  const calculateWage = () => {
    const individualLessonDiscountPrice =
      formData.individualPrice * (formData.individualLessonDiscount / 100);
    const groupLessonDiscountPrice = formData.groupPrice * (formData.groupLessonDiscount / 100);

    return { individualLessonDiscountPrice, groupLessonDiscountPrice };
  };

  const { individualLessonDiscountPrice, groupLessonDiscountPrice } = calculateWage();

  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap gap-2">
              <div className="d-flex align-items-center gap-2">
                <div className="card-title mb-0" style={{ flex: "1 0 auto", minWidth: "180px" }}>
                  <h4 className="mb-0">
                    {instructorData.name}님 {currentMonth}월 급여정산
                  </h4>
                </div>
                <select
                  className="form-select"
                  style={{ minWidth: "95px" }}
                  value={currentYear}
                  onChange={(e) => setCurrentYear(e.target.value)}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>{" "}
                <select
                  className="form-select"
                  value={currentMonth}
                  onChange={(e) => setCurrentMonth(e.target.value)}
                >
                  {months.map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
              </div>
              <div>
                <Button type="button" variant="btn btn-light" onClick={handleBackClick}>
                  뒤로가기
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">정산 요약</h4>
                <h5>
                  <li><code>강사료는 마감된 레슨 스케줄과 연동됩니다.</code></li>
                  <li><code>그룹레슨의 0~4인 설정된 금액로 계산되며 5인이상되는 경우 3만원으로 적용한다.</code></li>
                  <li><code>개인레슨은 설정된 금액의 참여한 인원을 곱하여 적용한다.</code></li>
                </h5>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="bd-example">
                <Form as={Row} className=" g-3">
                  <Col md="4">
                    <Form.Label htmlFor="instructorPrice">타임별수당</Form.Label>
                    <CommaFormattedInput
                      id="individualPrice"
                      value={wageData.defaultPrice}
                      disabled={true}
                    />
                    <Form.Text id="instructorPrice" muted>
                      강사의 설정된 급여를 보여줍니다.
                    </Form.Text>
                  </Col>
                  <Col md="4">
                    <Form.Label htmlFor="instructorLessonPrice">레슨비</Form.Label>
                    <CommaFormattedInput
                      id="instructorLessonPrice"
                      value={wageData.lessonPrice}
                      disabled={true}
                    />
                    <Form.Text id="instructorLessonPrice" muted>
                      해당 월에 마감된 레슨의 레슨비를 계산한 금액입니다.
                    </Form.Text>
                  </Col>
                  <Col md="4">
                    <Form.Label htmlFor="instructorOtherPrice">기타</Form.Label>
                    <CommaFormattedInput
                      id="instructorOtherPrice"
                      value={wageData.otherPrice}
                      disabled={true}
                    />
                    <Form.Text id="instructorOtherPrice" muted>
                      사용자가 추가한 기타 급여의 금액입니다.
                    </Form.Text>
                  </Col>
                  <Col md="4">
                    <Form.Label htmlFor="instructorSumPrice">총수당</Form.Label>
                    <CommaFormattedInput
                      id="sumPrice"
                      className="is-valid"
                      value={wageData.sumPrice}
                      disabled={true}
                    />
                  </Col>
                  <Col md="4">
                    <Form.Label htmlFor="instructorSumPrice">세금공제 적용 (3.3%)</Form.Label>
                    <CommaFormattedInput
                      id="sumPrice"
                      className="is-valid"
                      value={wageData.deductionPrice}
                      disabled={true}
                    />
                    <Form.Control.Feedback>3.3% 공제되어 정산된 합계 금액입니다.</Form.Control.Feedback>
                  </Col>
                  <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit}>
                      저장
                    </button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <div className="col-md-4">
          <InstructorWageGroupForm
            formData={formData}
            groupLessonDiscountPrice={groupLessonDiscountPrice}
            handleLessnDataChange={handleLessnDataChange}
          />
        </div>
        <div className="col-md-4">
          <InstructorWageIndividualForm
            formData={formData}
            individualLessonDiscountPrice={individualLessonDiscountPrice}
            handleLessnDataChange={handleLessnDataChange}
          />
        </div>
        <div className="col-md-4">
          <InstructorWageOtherForm
            formData={formData}
            handleOtherDataChange={handleOtherDataChange}
            addOtherData={addOtherData}
            removeOtherData={removeOtherData}
          />
        </div>
      </Row>
    </Fragment>
  );
});

export default InstructorWageView;
