import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Table, Button, Modal, Form } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";
import DeleteIcon from "../../../components/icon/DeleteIcon";
import ListButton from "../../../components/button/ListButton";
import { deleteLeaveLog, getLeaveDetailById, getLeaveLog, getLeaveUpdateLog, updateLeaveLog } from "../../../api/leave";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../../../utilities/dateFormatter";
import { leaveLogStatusTypeMapping, leaveTypeMapping } from "../../../mapping/leaveMapping";
import useUserMe from "../../../hooks/useUserMe";

const LeaveDetail = memo(() => {
  const [refreshData, setRefreshData] = useState(false);
  const { leaveId } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const instructorId = queryParams.get("instructorId");

  const { userMeData } = useUserMe();
  const myInstructorId = userMeData?.instructor?.id;

  const [leaveLogs, setLeaveLogs] = useState([]);
  const [leaveDetailData, setLeaveDetailData] = useState({
    annualLeaveDays: 0,
    halfDays: 0,
    quarterDays: 0,
    reason: "",
  });
  const [leaveUpdateLogs, setLeaveUpdateLogs] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleDeleteModalClose = () => setShowDeleteModal(false);
  const handleDeleteModalShow = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  }

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const handleUpdateModalShow = () => {
    // leaveDetailData.totalDay = totalDay
    setShowUpdateModal(true);
  }

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const details = await getLeaveLog(instructorId);
        const formattedDetails = details.map((detail) => ({
          ...detail,
          date: formatDate(detail.createdAt),
        }));
        setLeaveLogs(formattedDetails);
      } catch (error) {
        console.error("Failed to load getLeaveLog", error);
      }
    };
    fetchDetails();
  }, [instructorId, refreshData]);

  useEffect(() => {
    const fetchLeaveDetail = async () => {
      try {
        const leaveDetail = await getLeaveDetailById(leaveId);
        setLeaveDetailData(leaveDetail);
        // setTotalDay(leaveDetail.totalDay);
      } catch (error) {
        console.error("Failed to fetch getLeaveDetailById", error);
      }
    };

    fetchLeaveDetail();
  }, [leaveId]);

  useEffect(() => {
    const fetchLeaveUpdateLog = async () => {
      try {
        setLeaveUpdateLogs(await getLeaveUpdateLog(instructorId));
      } catch (error) {
        console.error("Failed to fetch getLeaveUpdateLog", error);
      }
    }

    fetchLeaveUpdateLog();
  }, [instructorId]);

  const handleLeaveDelete = async (id) => {
    try {
      await deleteLeaveLog(id);
      setRefreshData((prev) => !prev); // Toggle refreshData to trigger useEffect
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting leave log:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLeaveDetailData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleLeaveUpdate = async (id) => {
    try {
      const updateFormData = {
        id: leaveId,
        instructorId: instructorId,
        updateInstructorId: myInstructorId,
        annualLeaveDays: leaveDetailData.annualLeaveDays,
        halfDays: leaveDetailData.halfDays,
        quarterDays: leaveDetailData.quarterDays,
        reason: leaveDetailData.reason
      };
      await updateLeaveLog(updateFormData);
      setRefreshData((prev) => !prev); // Toggle refreshData to trigger useEffect
      setShowUpdateModal(false);
      alert('수정되었습니다.');
      window.location.reload();
    } catch (error) {
      console.error("Error deleting leave log:", error.message);
    }
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page in the history stack
  };

  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Card className="rounded">
            <Card.Body>
              <Row>
                <Col sm="12">
                  <h4 className=" mb-2 border-bottom pb-2">{leaveDetailData.user && leaveDetailData.user.name}</h4>
                  <h5 className="mb-3">{leaveDetailData && leaveDetailData.year}년 휴강내역</h5>
                  <Button type="button" variant="btn btn-light" onClick={handleBackClick}>
                    뒤로가기
                  </Button>{" "}
                  {userMeData && userMeData.instructor.position !== "BASIC" && (
                  <Button type="button" variant="btn btn-primary" onClick={handleUpdateModalShow}>
                    휴강 갯수 변경
                  </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="12" className=" mt-4">
                  <div className="table-responsive-lg">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">일자</th>
                          <th className="text-center" scope="col">
                            갯수
                          </th>
                          <th className="text-center" scope="col">
                            구분
                          </th>
                          <th className="text-center" scope="col">
                            사유
                          </th>
                          <th className="text-center" scope="col">
                            상태
                          </th>
                          <th className="text-center" scope="col">
                            삭제
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveLogs.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="text-center">
                              내역이 없습니다
                            </td>
                          </tr>
                        ) : (
                          leaveLogs.map((detail, index) => (
                            <tr key={index}>
                              <td>
                                <h6 className="mb-0">
                                  {detail.leaveStartDate} ~ {detail.leaveEndDate}
                                </h6>
                              </td>
                              <td className="text-center">{detail.useDay}</td>
                              <td className="text-center">{leaveTypeMapping[detail.leaveType]}</td>
                              <td className="text-center">{detail.memo}</td>
                              <td className="text-center">{leaveLogStatusTypeMapping[detail.status]}</td>
                              <td className="text-center">
                                {userMeData && userMeData.instructor.position !== "BASIC" && (
                                <ListButton
                                  onClick={() => handleDeleteModalShow(detail.id)}
                                  tooltipTitle="Delete"
                                  ariaLabel="Delete"
                                  className="btn-danger"
                                >
                                  <DeleteIcon />
                                </ListButton>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <Modal
                    show={showDeleteModal}
                    onHide={handleDeleteModalClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>휴강 삭제</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      선택한 휴강의 데이터를 삭제하면 차감된 일수는 복구됩니다.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteModalClose}>
                        닫기
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleLeaveDelete(`${deleteId}`)}
                      >
                        삭제
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="rounded">
            <Card.Body>
              <Row>
                <Col sm="12">
                  <h4 className=" mb-2 border-bottom pb-2">휴강 변경내역</h4>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className=" mt-4">
                  <div className="table-responsive-lg">
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">내용</th>
                          <th className="text-center" scope="col">
                            변경사유
                          </th>
                          <th className="text-center" scope="col">
                            변경일
                          </th>
                          <th className="text-center" scope="col">
                            담당자
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveUpdateLogs.length === 0 ? (
                          <tr>
                            <td colSpan="4" className="text-center">
                              내역이 없습니다
                            </td>
                          </tr>
                        ) : (
                          leaveUpdateLogs.map((log, index) => (
                            <tr key={index}>
                              <td>{log.message}</td>
                              <td className="text-center">{log.reason}</td>
                              <td className="text-center">{formatDate(log.createdAt)}</td>
                              <td className="text-center">{log.updateInstructor.name}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <Modal
                    show={showDeleteModal}
                    onHide={handleDeleteModalClose}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>휴강 삭제</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      선택한 휴강의 데이터를 삭제하면 차감된 일수는 복구됩니다.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleDeleteModalClose}>
                        닫기
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleLeaveDelete(`${deleteId}`)}
                      >
                        삭제
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Modal show={showUpdateModal} onHide={handleUpdateModalClose}>
            <Modal.Header closeButton>
              <Modal.Title as="h5">레슨 상세</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label htmlFor="count">휴강</Form.Label>
                  <Form.Control
                    type="number"
                    name="annualLeaveDays"
                    value={leaveDetailData.annualLeaveDays}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label htmlFor="count">반휴강</Form.Label>
                  <Form.Control
                    type="number"
                    name="halfDays"
                    value={leaveDetailData.halfDays}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label htmlFor="count">반반휴강</Form.Label>
                  <Form.Control
                    type="number"
                    name="quarterDays"
                    value={leaveDetailData.quarterDays}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="col-md-12 form-group">
                  <Form.Label htmlFor="add2">변경 사유</Form.Label>
                  <Form.Control
                    type="text"
                    name="reason"
                    value={leaveDetailData.reason}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleLeaveUpdate}>
                저장
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Fragment>
  );
});

LeaveDetail.displayName = "LeaveDetail";
export default LeaveDetail;
