export const leaveTypeMapping = {
  ANNUAL: "휴강",
  HALF: "반휴강",
  QUARTER: "반반휴강"
};

export const leaveLogStatusTypeMapping = {
  REQUEST: "신청",
  APPROVE: "승인",
  REJECT: "반려"
}

export const leaveTypeDetailMapping = {
  ANNUAL: {
    totalDays: 'annualLeaveDays',
    usedDays: 'annualLeaveUseDays',
  },
  HALF: {
    totalDays: 'halfDays',
    usedDays: 'halfUseDays',
  },
  QUARTER: {
    totalDays: 'quarterDays',
    usedDays: 'quarterUseDays',
  },
};